import React, { useContext, useState } from "react";
import {
  StyledClientCardsScroller,
  StyledClientCardsScrollerItems,
  StyledClientCardsScrollerItem,
  StyledClientCardsScrollerItemContent,
  StyledClientCardsScrollerItemText,
} from "./style.jsx";
import Icon, { icons } from "../Icon/Icon";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Colors } from "../../commons/Theme.js";
import { Button } from "@3beehivetech/components";
import { DataContext } from "../../contexts/DataContext.jsx";
import Link from "../Link/Link.jsx";

const ClientCardsScroller = ({ items }) => {
  return (
    <StyledGridRow isFullMobile>
      <StyledClientCardsScroller>
        <Scroller>
          <StyledClientCardsScrollerItems>
            {items.map((item, index) => <ClientCardsScrollerItem key={index} item={item}/>)}
          </StyledClientCardsScrollerItems>
        </Scroller>
      </StyledClientCardsScroller>
    </StyledGridRow>
  );
};

const ClientCardsScrollerItem = ({ item }) => {
  const [isHover, setIsHover] = useState(false);
  const { pageImages } = useContext(DataContext);  

  const imageData = pageImages.find(e => e.path === item.background);
  const imageLogoData = pageImages.find(e => e.path === item.logo);

  return (
    <StyledClientCardsScrollerItem>
      <GatsbyImage 
        imgStyle={{ borderRadius: "10px", filter: "brightness(0.5)" }} 
        image={getImage(imageData)} 
        alt={imageData?.alt} />

      <StyledClientCardsScrollerItemContent>
        <GatsbyImage 
          style={{ height: "50px" }}
          imgStyle={{ 
            height: "50px", 
            maxWidth: "75vw", 
            width: "350px", 
            objectFit: "contain", 
            objectPosition: "left",
          }} 
          image={getImage(imageLogoData)} 
          alt={imageLogoData?.alt} />

        <StyledClientCardsScrollerItemText dangerouslySetInnerHTML={{ __html: item.text }}/>
        
        {item.cta?.url && (
          <Link
            to={item.cta.url}>
            <Button style={{ marginTop: "auto" }} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
              {item.cta.label}{" "}
              {isHover 
                ? <Icon icon={icons.arrowRight} color={Colors.yellow} /> 
                : <Icon icon={icons.arrowRight} color={Colors.white} />
              }
            </Button>
          </Link>
        )}
      </StyledClientCardsScrollerItemContent>
      
    </StyledClientCardsScrollerItem>
  );
};

export default ClientCardsScroller;

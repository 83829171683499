import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledClientCardsScroller = styled.div``;

export const StyledClientCardsScrollerItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:after {
    content: '';
    display: block;
    min-width: ${Rem(50)};
    max-width: ${Rem(50)};
    height: 1px;

    @media (${BP.ipad}) {
      display: none;
    }
  }
`;

export const StyledClientCardsScrollerItem = styled.div`
  position: relative;
  color: white;
  margin-left: ${Rem(16)};

  width: 90vw;
  height: ${Rem(450)};
  @media (${BP.tablet}) {
    width: ${Rem(400)};
    height: ${Rem(400)};
  }

  .gatsby-image-wrapper {
    width: 90vw;
    height: ${Rem(450)};
    @media (${BP.tablet}) {
      width: ${Rem(400)};
      height: ${Rem(400)};
    }
  }
`;

export const StyledClientCardsScrollerItemContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${Rem(20)};
  padding: ${Rem(20)};
`;

export const StyledClientCardsScrollerItemText = styled.p`
  font-size: ${Rem(14)};
  line-height: ${Rem(19)};
  font-weight: normal;

  @media (${BP.tablet}) {
    font-size: ${Rem(16)};
    line-height: ${Rem(21)};
  }
`;
